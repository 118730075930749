<template>
  <div>
    <k-crud-page>
      <template #condition>
        <div class="d-flex flex-wrap my-4">
          <k-text-input
            v-model="cruddataMX.conditions.searchParam"
            class="my-1"
            label="机构信息"
          />
          <div class="d-flex justify-end ml-auto">
            <k-primary-button
              class="my-1 mr-4"
              text="检索"
              icon="mdi-magnify"
              @click="onclick"
            />
          </div>
        </div>
      </template>
      <template #resultlist>
        <k-crud-table
          :headers="columns"
          :items="cruddataMX.dataList"
          :value="cruddataMX.pagination.current"
          :total="cruddataMX.pagination.total"
          @pageChanged="
            (page) => {
              crudPaginationMX().change(page);
            }
          "
        >
          <template #top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <k-primary-button
                icon="mdi-plus"
                text="新增分析项目配置"
                @click="
                  crudActionMX().newItem(crud.default, onOpenEdit(crud.default))
                "
              />
              <k-crud-dialog
                v-model="crud.switch"
                :title="crud.title"
                maxwidth="900px"
                @save="
                  () => {
                    crudActionMX().saveItem();
                  }
                "
                @close="
                  () => {
                    crudActionMX().closeEditingPage(crud);
                  }
                "
              >
                <template #dialogContent>
                  <v-form :ref="crud.form.ref">
                    <v-row>
                      <v-col cols="12" md="4">
                        <k-select
                          v-model="cruddataMX.data.organizationUuid"
                          :items="organizationList"
                          label="机构"
                          :loading="crud.select.roles.loading"
                          :disabled="crud.select.roles.loading"
                          :clearable="clearable"
                          class="my-2"
                          @input="
                            changeOrganization(cruddataMX.data.organizationUuid)
                          "
                        />
                      </v-col>
                      <v-col cols="12" md="4">
                        <k-select
                          v-model="cruddataMX.data.psmUuid"
                          :items="psmList"
                          label="主机"
                          :loading="crud.select.roles.loading"
                          :disabled="psmList.length <= 0"
                          :clearable="clearable"
                          class="my-2"
                        />
                      </v-col>
                      <v-col cols="12" md="4">
                        <k-select
                          v-model="cruddataMX.data.analysisItemsUuid"
                          :items="analysisItemsList"
                          label="分析项目"
                          :loading="crud.select.roles.loading"
                          :disabled="crud.select.roles.loading"
                          :clearable="clearable"
                          class="my-2"
                        />
                      </v-col>
                    </v-row>
                    <v-card class="mt-2">
                      <div style="position: absolute; top: -1em; left: 2em">
                        配置参数
                      </div>
                      <v-row>
                        <v-col cols="12" md="4">
                          <k-select
                            v-model="cruddataMX.data.organizationUuid"
                            :items="organizationList"
                            label="柜台"
                            :loading="crud.select.roles.loading"
                            :disabled="crud.select.roles.loading"
                            :clearable="clearable"
                            class="my-2"
                            @input="
                              changeOrganization(
                                cruddataMX.data.organizationUuid
                              )
                            "
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <k-select
                            v-model="cruddataMX.data.psmUuid"
                            :items="psmList"
                            label="摄像头"
                            :loading="crud.select.roles.loading"
                            :disabled="crud.select.roles.loading"
                            :clearable="clearable"
                            class="my-2"
                          />
                        </v-col>
                        <v-col cols="12" md="4">
                          <k-primary-button
                            class="my-2"
                            text="刷新画面"
                            @click="rePage"
                          />
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-form>
                </template>
              </k-crud-dialog>
            </v-toolbar>
          </template>
          <template #status="{ item }">
            <k-switch
              v-model="item.statusModel"
              :loading="item.loading"
            ></k-switch>
          </template>
          <template #actions="{ item }">
            <v-icon
              class="mr-2"
              @click="crudActionMX().editItem(item, onOpenEdit(item))"
            >
              mdi-pencil
            </v-icon>
            <v-icon @click="crudActionMX().deleteItem(item)">mdi-delete</v-icon>
          </template>
        </k-crud-table>
      </template>
    </k-crud-page>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      rules: {
        required: (value) => !!value || 'Required.',
      },
      crud: {
        name: '分析项目',
        title: '', // 在Mixin中被修改
        switch: false,
        uploading: false,
        loading: false,
        form: {
          ref: 'accountModalForm2',
        },
        select: {
          roles: {
            loading: false,
            current: 1,
            defaultPageSize: 10,
            data: [],
            dataset: [],
          },
        },
        default: {
          organizationUuid: '',
          moduleUuid: '',
          analysisItemsUuid: '',
          areaUuid: '',
          locationUuid: '',
          camera1Uuid: '',
          camera2Uuid: '',
          psmUuid: '',
          ipAddr: '',
          path: '',
          psmList: [],
        },
      },
      columns: [
        { text: '序号', value: 'indexNum', sortable: false },
        { text: '机构名称', value: 'orgName' },
        { text: '金融数字员工类型', value: 'moduleName' },
        { text: '分析项目', value: 'itemName' },
        { text: '位置', value: 'areaName' },
        { text: '分析服务器', value: 'ipAddr' },
        { text: '摄像头1', value: 'cameraName1', sortable: false },
        { text: '摄像头2', value: 'cameraName2', sortable: false },
        { text: '状态', value: 'status' },
        { text: '操作', value: 'actions', sortable: false },
      ],
      organizationList: [],
      psmList: [],
      clearable: false,
      analysisItemsList: [],
    };
  },
  computed: {},
  created() {
    this.getOrganizationList();
    this.getanalysisItemsList();
    this.crudInitMX(
      this.crud,
      {
        list: this.$api.nodeAnalysis.nodeAnalysis.getNodeAnalysis,
        add: this.$api.device.hardware.addAnalysisServer,
        edit: this.$api.device.hardware.editAnalysisServer,
        delete: this.$api.device.hardware.deleteAnalysisServer,
      },
      {},
      {
        add: ['organizationUuid', 'psmName', 'ipAddr', 'sn'],
        edit: ['uuid', 'organizationUuid', 'psmName', 'ipAddr', 'sn'],
      }
    );
  },
  methods: {
    onOpenEdit(item) {
      item.roles = [];
      item.roleUuids = [];
      item.roleVos?.forEach((p) => {
        item.roles.push(p.name);
        item.roleUuids.push(p.uuid);
      });
      this.psmList = item.psmList;
    },
    onclick() {
      alert('检索');
    },
    rePage() {
      alert('刷新画面');
    },
    getOrganizationList() {
      this.$api.device.hardware.getOrganizationList().then((res) => {
        this.organizationList = res.data;
      });
    },
    changeOrganization(organizationUuid) {
      if (organizationUuid != null && organizationUuid != undefined) {
        if (organizationUuid == '') {
          this.psmList = [];
        } else {
          this.getPsmList(organizationUuid);
        }
      }
    },
    getPsmList(organizationUuid) {
      this.psmList = [];
      let param = {
        organizationUuid: organizationUuid,
      };
      this.$api.nodeAnalysis.nodeAnalysis.getPsmList(param).then((res) => {
        this.psmList = res.data;
      });
    },
    getanalysisItemsList() {
      this.$api.nodeAnalysis.nodeAnalysis.getanalysisItemsList().then((res) => {
        this.analysisItemsList = res.data;
      });
    },
  },
};
</script>
