var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('k-crud-page',{scopedSlots:_vm._u([{key:"condition",fn:function(){return [_c('div',{staticClass:"d-flex flex-wrap my-4"},[_c('k-text-input',{staticClass:"my-1",attrs:{"label":"机构信息"},model:{value:(_vm.cruddataMX.conditions.searchParam),callback:function ($$v) {_vm.$set(_vm.cruddataMX.conditions, "searchParam", $$v)},expression:"cruddataMX.conditions.searchParam"}}),_c('div',{staticClass:"d-flex justify-end ml-auto"},[_c('k-primary-button',{staticClass:"my-1 mr-4",attrs:{"text":"检索","icon":"mdi-magnify"},on:{"click":_vm.onclick}})],1)],1)]},proxy:true},{key:"resultlist",fn:function(){return [_c('k-crud-table',{attrs:{"headers":_vm.columns,"items":_vm.cruddataMX.dataList,"value":_vm.cruddataMX.pagination.current,"total":_vm.cruddataMX.pagination.total},on:{"pageChanged":function (page) {
            _vm.crudPaginationMX().change(page);
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('k-primary-button',{attrs:{"icon":"mdi-plus","text":"新增分析项目配置"},on:{"click":function($event){_vm.crudActionMX().newItem(_vm.crud.default, _vm.onOpenEdit(_vm.crud.default))}}}),_c('k-crud-dialog',{attrs:{"title":_vm.crud.title,"maxwidth":"900px"},on:{"save":function () {
                  _vm.crudActionMX().saveItem();
                },"close":function () {
                  _vm.crudActionMX().closeEditingPage(_vm.crud);
                }},scopedSlots:_vm._u([{key:"dialogContent",fn:function(){return [_c('v-form',{ref:_vm.crud.form.ref},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('k-select',{staticClass:"my-2",attrs:{"items":_vm.organizationList,"label":"机构","loading":_vm.crud.select.roles.loading,"disabled":_vm.crud.select.roles.loading,"clearable":_vm.clearable},on:{"input":function($event){return _vm.changeOrganization(_vm.cruddataMX.data.organizationUuid)}},model:{value:(_vm.cruddataMX.data.organizationUuid),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "organizationUuid", $$v)},expression:"cruddataMX.data.organizationUuid"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('k-select',{staticClass:"my-2",attrs:{"items":_vm.psmList,"label":"主机","loading":_vm.crud.select.roles.loading,"disabled":_vm.psmList.length <= 0,"clearable":_vm.clearable},model:{value:(_vm.cruddataMX.data.psmUuid),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "psmUuid", $$v)},expression:"cruddataMX.data.psmUuid"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('k-select',{staticClass:"my-2",attrs:{"items":_vm.analysisItemsList,"label":"分析项目","loading":_vm.crud.select.roles.loading,"disabled":_vm.crud.select.roles.loading,"clearable":_vm.clearable},model:{value:(_vm.cruddataMX.data.analysisItemsUuid),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "analysisItemsUuid", $$v)},expression:"cruddataMX.data.analysisItemsUuid"}})],1)],1),_c('v-card',{staticClass:"mt-2"},[_c('div',{staticStyle:{"position":"absolute","top":"-1em","left":"2em"}},[_vm._v(" 配置参数 ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('k-select',{staticClass:"my-2",attrs:{"items":_vm.organizationList,"label":"柜台","loading":_vm.crud.select.roles.loading,"disabled":_vm.crud.select.roles.loading,"clearable":_vm.clearable},on:{"input":function($event){return _vm.changeOrganization(
                              _vm.cruddataMX.data.organizationUuid
                            )}},model:{value:(_vm.cruddataMX.data.organizationUuid),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "organizationUuid", $$v)},expression:"cruddataMX.data.organizationUuid"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('k-select',{staticClass:"my-2",attrs:{"items":_vm.psmList,"label":"摄像头","loading":_vm.crud.select.roles.loading,"disabled":_vm.crud.select.roles.loading,"clearable":_vm.clearable},model:{value:(_vm.cruddataMX.data.psmUuid),callback:function ($$v) {_vm.$set(_vm.cruddataMX.data, "psmUuid", $$v)},expression:"cruddataMX.data.psmUuid"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('k-primary-button',{staticClass:"my-2",attrs:{"text":"刷新画面"},on:{"click":_vm.rePage}})],1)],1)],1)],1)]},proxy:true}]),model:{value:(_vm.crud.switch),callback:function ($$v) {_vm.$set(_vm.crud, "switch", $$v)},expression:"crud.switch"}})],1)]},proxy:true},{key:"status",fn:function(ref){
                            var item = ref.item;
return [_c('k-switch',{attrs:{"loading":item.loading},model:{value:(item.statusModel),callback:function ($$v) {_vm.$set(item, "statusModel", $$v)},expression:"item.statusModel"}})]}},{key:"actions",fn:function(ref){
                            var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){_vm.crudActionMX().editItem(item, _vm.onOpenEdit(item))}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{on:{"click":function($event){_vm.crudActionMX().deleteItem(item)}}},[_vm._v("mdi-delete")])]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }